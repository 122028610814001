import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TemplatesComponent from '../components/TemplatesComponent';
import TemplatesEmptyComponent from '../components/TemplatesEmptyComponent';
import { fetchTemplates } from 'controller/TemplatesSlice';

import { useTranslation } from 'react-i18next';

function TemplatesPage() {

    const { t,i18n } = useTranslation();

    const { templates, isTemplatesFetched } = useSelector((state) => state.templates);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isTemplatesFetched) {
            dispatch(fetchTemplates());
        }
    }, [dispatch, isTemplatesFetched]);

    return (
        <>
            <section className="bg-color-7">
                <hr className="hr-header" />
                <div className="container text-color-6">
                    <h1 className="pl-m pl-s-on-breakpoint-m pl-0-on-breakpoint-s">{t('templatesPage.title')}</h1>
                    <hr className="hr-0 hr-l-on-breakpoint-xl"/>
                    <div className="grid-2 justify-end grid-5-on-breakpoint-xl grid-8-on-breakpoint-s gap-0-on-breakpoint-s grid-1-on-breakpoint-xxs">
                        <div className="col-start-2 col-start-3-on-breakpoint-xl col-end-6-on-breakpoint-xl col-start-2-on-breakpoint-m col-end-9-on-breakpoint-s">
                            <p className="text-l">
                                {t('templatesPage.textPart1')}
                                <br /><br />
                                {t('templatesPage.textPart2')}
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="hr-xxxl" />
            </section>
            <section>
                <div className="container-m">
                    <div className="grid-2 gap-xxxl gap-xxl-on-breakpoint-l gap-xl-on-breakpoint-m gap-l-on-breakpoint-sm grid-1-on-breakpoint-xs gap-xl-on-breakpoint-xs">
                        {templates && templates.length > 0 ?
                            <TemplatesComponent templates={templates}></TemplatesComponent> : <TemplatesEmptyComponent></TemplatesEmptyComponent>
                        }
                    </div>
                </div>
            </section>
        </>
    );

}

export default TemplatesPage