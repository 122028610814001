import axios from 'axios'

export const API_ENDPOINTS = {
    GET_TEAM: `${process.env.REACT_APP_API_SERVER}/team`,
    GET_CAREERS: `${process.env.REACT_APP_API_SERVER}/careers`,
    GET_EBOOKS: `${process.env.REACT_APP_API_SERVER}/ebooks`,
    GET_TEMPLATES: `${process.env.REACT_APP_API_SERVER}/templates`,
    GET_TESTIMONIALS: `${process.env.REACT_APP_API_SERVER}/testimonials`,
    GET_CLIENTS: `${process.env.REACT_APP_API_SERVER}/clients`,
    POST_NEWSLETTER: `${process.env.REACT_APP_API_SERVER}/newsletter`,
    POST_CONTACTS: `${process.env.REACT_APP_API_SERVER}/contacts`,
};

export const getReturnData = async (endpoint) => {
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.log(error)
    }
};

export const postData = async (endpoint,data) => {
    try {
        const response = await axios.post(endpoint, data);
        return response.data;
      } catch (error) {
        console.error('Error making POST request:', error);
        throw error;
      }
};