import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateFetchActionReducers, generateExtraReducers } from 'controller/scripts/Utils';
import * as api from 'controller/api/API';

const initialState = {
    ebooks: [],
    status: 'idle',
    error: null,
    isEbooksFetched: false
};

const TEAM_ACTIONS = {
    FETCH_EBOOKS: 'ebooks/fetchEbooks',
};

export const fetchEbooks = createAsyncThunk(
    TEAM_ACTIONS.FETCH_EBOOKS,
    async () => {
        const response = await api.getReturnData(api.API_ENDPOINTS.GET_EBOOKS);
        return response;
    }
);

const fetchActions = [
    { action: fetchEbooks, reducers: generateFetchActionReducers('ebooks') },    
];

const EbooksSlice = createSlice({
    name: 'ebooks',
    initialState,
    reducers: {
        setEbooksFetched: (state, action) => {
            state.isEbooksFetched = action.payload;
        },
    },
    extraReducers: generateExtraReducers(fetchActions),
});

export const { setEbooksFetched } = EbooksSlice.actions;

export default EbooksSlice.reducer;