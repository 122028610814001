import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateFetchActionReducers, generateExtraReducers } from 'controller/scripts/Utils';
import * as api from 'controller/api/API';

const initialState = {
    team: [],
    status: 'idle',
    error: null,
    isTestimonialsFetched: false
};

const TEAM_ACTIONS = {
    FETCH_TESTIMONIALS: 'testimonials/fetchTestimonials',
};

export const fetchTestimonials = createAsyncThunk(
    TEAM_ACTIONS.FETCH_TESTIMONIALS,
    async () => {
        const response = await api.getReturnData(api.API_ENDPOINTS.GET_TESTIMONIALS);
        return response;
    }
);

const fetchActions = [
    { action: fetchTestimonials, reducers: generateFetchActionReducers('testimonials') },    
];

const TestimonialsSlice = createSlice({
    name: 'testimonials',
    initialState,
    reducers: {
        setTestimonialsFetched: (state, action) => {
            state.isTestimonialsFetched = action.payload;
        },
    },
    extraReducers: generateExtraReducers(fetchActions),
});

export const { setTestimonialsFetched } = TestimonialsSlice.actions;

export default TestimonialsSlice.reducer;