import { getLocalizedContent } from 'controller/scripts/Utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

function CareerEmptyComponent() {

    const { t,i18n } = useTranslation();

    return (
        <>
            <div className="open-opportunity-item">
                <h3 className="col-all w-100 text-gray text-center">{t('careersPage.emptyMessage')}</h3>
            </div>
        </>
    );
}

export default CareerEmptyComponent