import React from 'react';

import ValuesComponent from '../components/ValuesComponent';
import { useTranslation } from 'react-i18next';

function AboutPage() {

  const { t,i18n } = useTranslation();

  return (
    <>
        <section className="bg-color-2">
            <hr className="hr-header"/>
            <div className="container">
                <h1 className="pl-m pl-s-on-breakpoint-m pl-0-on-breakpoint-s">{t('aboutPage.title')}</h1>
                <hr className="hr-m hr-s-on-breakpoint-m hr-xs-on-breakpoint-s"/>
                <h3 className="pl-xxl pl-xl-on-breakpoint-l pl-0-on-breakpoint-s">{t('aboutPage.subtitle')}</h3>
                <hr className="hr-0 hr-l-on-breakpoint-m"/>
                <div className="grid-2 justify-end grid-5-on-breakpoint-xl grid-8-on-breakpoint-s gap-0-on-breakpoint-s grid-1-on-breakpoint-xxs">
                    <div className="col-start-2 col-start-3-on-breakpoint-xl col-end-6-on-breakpoint-xl col-start-2-on-breakpoint-m col-end-9-on-breakpoint-s">
                        <p className="text-l text-ml-on-breakpoint-l text-m-on-breakpoint-s text-sm-on-breakpoint-xxs">
                          {t('aboutPage.textPart1')}
                          <br/><br/>
                          {t('aboutPage.textPart2')}
                        </p>
                    </div>
                </div>
            </div>
            <hr className="hr-xxxl"/>
        </section>

        <hr className="hr-xl hr-m-on-breakpoint-ml hr-0-on-breakpoint-m"/>

        <section>
          <div className="container-m">
            <div className="container-sm p-0">
              <hr className="bg-black"/>
              <hr className="hr-xl hr-l-on-breakpoint-xxs"/>

              <div className="grid-2 gap-m-on-breakpoint-s grid-1-on-breakpoint-xxs">
                <h2>{t('aboutPage.component1.unlock')}<br/>{t('aboutPage.component1.thePower')}<br/>{t('aboutPage.component1.ofInnovation')}</h2>
                <p>
                  {t('aboutPage.component1.text')}
                </p>
              </div>

              <hr className="hr-xl hr-l-on-breakpoint-xxs"/>
              <hr className="bg-black"/>
              <hr className="hr-xl hr-l-on-breakpoint-xxs"/>

              <div className="grid-2 gap-m-on-breakpoint-s grid-1-on-breakpoint-xxs">
                <h2>{t('aboutPage.component2.positive')}<br/>{t('aboutPage.component2.change')}</h2>
                <p>
                  {t('aboutPage.component2.text')}
                </p>
              </div>

              <hr className="hr-xl hr-l-on-breakpoint-xxs"/>
              <hr className="bg-black"/>
              <hr className="hr-xl hr-l-on-breakpoint-xxs"/>

              <div className="grid-2 gap-m-on-breakpoint-s grid-1-on-breakpoint-xxs">
                <h2>{t('aboutPage.component3.opportunity')}<br/>{t('aboutPage.component3.focus')}</h2>
                <p>
                  {t('aboutPage.component3.text')}
                </p>
              </div>

              <hr className="hr-xl hr-l-on-breakpoint-xxs"/>
              <hr className="bg-black"/>
            </div>

            <hr className="hr-xxl hr-m-on-breakpoint-l hr-0-on-breakpoint-xxs"/>
            <hr className="hr-xxl hr-xl-on-breakpoint-xxs"/>
            <div className="container-sm p-0 m-0">
              <h2 className="quote unlocking-quote">“{t('quote.unlocking')}<br/>{t('quote.tangibleImpact')}<br/>{t('quote.through')}<br/>{t('quote.collaborative')}<br/>{t('quote.innovation')}”</h2>
            </div>
            <hr className="hr-xxl hr-xl-on-breakpoint-l"/>
            <div className="grid-2 grid-3-on-breakpoint-sm grid-1-on-breakpoint-s">
              <p className="col-2-on-breakpoint-sm col-all-on-breakpoint-s">
                {t('quote.weAreAnInnovationAgency')}
                <br/><br/>
                {t('quote.weGuideYou')}
                <br/><br/>
                {t('quote.weAreDealing')}
                <br/><br/>
                {t('quote.weDoItTogether')}
              </p>
            </div>

          </div>
        </section>

        <section className="bg-color-4">
          <div className="container-m">
            <div className="grid-2 gap-xxl gap-xl-on-breakpoint-m grid-1-on-breakpoint-s">
              <div className="flex-column gap-m">
                <h2 className="text-primary">{t('aboutPage.mission.title')}</h2>
                <p className="text-white text-xl text-l-on-breakpoint-xl text-ml-on-breakpoint-m">
                  {t('aboutPage.mission.text')}
                </p>
              </div>
              <div className="flex-column gap-m">
                <h2 className="text-primary">{t('aboutPage.vision.title')}</h2>
                <p className="text-white text-xl text-l-on-breakpoint-xl text-ml-on-breakpoint-m">
                  {t('aboutPage.vision.text')}
                </p>
              </div>
            </div>
          </div>
        </section>

        <ValuesComponent></ValuesComponent>
    </>
  );
  
}

export default AboutPage