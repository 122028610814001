import React, { useEffect } from 'react';

import ButtonArrow from 'render/assets/media/auxiliary/button-arrow-triggr-innovation.svg';
import Placeholder from 'render/assets/media/auxiliary/section-2-img-triggr-innovation.jpg';
import ValuesComponent from '../components/ValuesComponent';
import CareerComponent from '../components/CareerComponent';
import CareerEmptyComponent from '../components/CareerEmptyComponent';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCareers } from 'controller/CareerSlice';

import { useTranslation } from 'react-i18next';

function CareersPage() {

  const { t,i18n } = useTranslation();

  const { careers, isCareersFetched } = useSelector((state) => state.careers);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isCareersFetched) {
      dispatch(fetchCareers());
    }
  }, [dispatch, isCareersFetched]);

  return (
    <>
      <section className="bg-color-7">
        <hr className="hr-header" />
        <div className="container">
          <h1 className="pl-m pl-s-on-breakpoint-m pl-0-on-breakpoint-s">{t('careersPage.title')}</h1>
          <hr className="hr-m hr-s-on-breakpoint-m hr-xs-on-breakpoint-s"/>
          <h3 className="pl-xxl pl-xl-on-breakpoint-l pl-0-on-breakpoint-s">{t('careersPage.subtitle')}</h3>
          <hr className="hr-0 hr-l-on-breakpoint-m"/>
          <div className="grid-2 justify-end grid-5-on-breakpoint-xl grid-8-on-breakpoint-s gap-0-on-breakpoint-s grid-1-on-breakpoint-xxs">
            <div className="col-start-2 col-start-3-on-breakpoint-xl col-end-6-on-breakpoint-xl col-start-2-on-breakpoint-m col-end-9-on-breakpoint-s">
              <p className="text-l text-ml-on-breakpoint-l text-m-on-breakpoint-s text-sm-on-breakpoint-xxs">
                {t('careersPage.text')}
              </p>
            </div>
          </div>
        </div>
        <hr className="hr-xxxl" />
      </section>
      
      <ValuesComponent></ValuesComponent>

      <section className="bg-color-6">
        <div className="container-ml">
          <header className="open-opportunities-header">
            <h2>{t('careersPage.open')}<br />{t('careersPage.opportunities')}</h2>
            <div className="container-xxs p-0 mx-0-on-breakpoint-xs">
              <p className="text-l text-m-on-breakpoint-l text-sm-on-breakpoint-xs">
                {t('careersPage.openOpportunitiesText')}
              </p>
              <hr className="hr-xl hr-l-on-breakpoint-l"/>
              <a href="mailto:geral@triggrinnovations.com" className="justify-start btn btn-arrow p-0"><span>{t('careersPage.spontaneousApplication')}</span><img src={ButtonArrow}/></a>
            </div>
          </header>
          {careers && careers.length > 0 ?
            <CareerComponent careers={careers}></CareerComponent> : <CareerEmptyComponent></CareerEmptyComponent>
          }
        </div>
      </section>
    </>
  );

}

export default CareersPage