import { getLocalizedContent } from 'controller/scripts/Utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

function TeamComponent({ team }) {

    const {i18n} = useTranslation();

    return (
        <>
            {team && team.map((team_member, index) => {
                const localizedContent = team_member ? getLocalizedContent(team_member, i18n.language, ['position', 'biography']) : null;
                return (
                    <div className="team-member-item" key={index}>
                        <div className="image-container">
                            <img className="img-cover" src={`${process.env.REACT_APP_API_SERVER}/${team_member.photo}`} alt="" />
                        </div>
                        <h4 className="team-member-item--name">{team_member.name}</h4>
                        <h6 className="team-member-item--position">{localizedContent.position}</h6>
                        <p>
                            {localizedContent.biography}
                        </p>
                    </div>
                )

            })}
        </>
    );
}

export default TeamComponent