import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateFetchActionReducers, generateExtraReducers } from 'controller/scripts/Utils';
import * as api from 'controller/api/API';

const initialState = {
    clients: [],
    status: 'idle',
    error: null,
    isClientsFetched: false
};

const CLIENTS_ACTIONS = {
    FETCH_CLIENTS: 'clients/fetchClients',
};

export const fetchClients = createAsyncThunk(
    CLIENTS_ACTIONS.FETCH_CLIENTS,
    async () => {
        const response = await api.getReturnData(api.API_ENDPOINTS.GET_CLIENTS);
        return response;
    }
);

const fetchActions = [
    { action: fetchClients, reducers: generateFetchActionReducers('clients') },    
];

const ClientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setClientsFetched: (state, action) => {
            state.isClientsFetched = action.payload;
        },
    },
    extraReducers: generateExtraReducers(fetchActions),
});

export const { setClientsFetched } = ClientsSlice.actions;

export default ClientsSlice.reducer;