import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEbooks } from 'controller/EbooksSlice';
import EbookComponent from '../components/EbookComponent';
import EBookEmptyComponent from '../components/EBookEmptyComponent';

import { useTranslation } from 'react-i18next';

function EbooksPage() {

    const { t,i18n } = useTranslation();

    const { ebooks, isEbooksFetched } = useSelector((state) => state.ebooks);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEbooksFetched) {
            dispatch(fetchEbooks());
        }
    }, [dispatch, isEbooksFetched]);


    return (
        <>
            <section className="bg-color-4">
                <hr className="hr-header" />
                <div className="container text-primary">
                    <h1 className="pl-m pl-s-on-breakpoint-m pl-0-on-breakpoint-s">{t('ebooksPage.title')}</h1>
                    <hr className="hr-0 hr-l-on-breakpoint-m"/>
                    <div className="grid-2 justify-end grid-5-on-breakpoint-xl grid-8-on-breakpoint-s gap-0-on-breakpoint-s grid-1-on-breakpoint-xxs">
                        <div className="col-start-2 col-start-3-on-breakpoint-xl col-end-6-on-breakpoint-xl col-start-2-on-breakpoint-m col-end-9-on-breakpoint-s">
                            <p className="text-l text-ml-on-breakpoint-l text-m-on-breakpoint-s text-sm-on-breakpoint-xxs">
                                {t('ebooksPage.textPart1')}
                                <br /><br />
                                {t('ebooksPage.textPart2')}
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="hr-xxxl" />
            </section>
            <section>
                <div className="container-m">
                <div className="grid-2 gap-xxxl gap-xxl-on-breakpoint-l gap-xl-on-breakpoint-m gap-l-on-breakpoint-sm grid-1-on-breakpoint-xs gap-xl-on-breakpoint-xs">
                        {ebooks && ebooks.length > 0 ?
                            <EbookComponent ebooks={ebooks}></EbookComponent> : <EBookEmptyComponent></EBookEmptyComponent>
                        }
                    </div>
                </div>
            </section>
        </>
    );

}

export default EbooksPage