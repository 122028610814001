import RoutesFile from 'routes/RoutesFile';
import HeaderComponent from 'render/views/components/HeaderComponent';
import FooterComponent from 'render/views/components/FooterComponent';
import { MainProvider } from 'providers/MainProvider';
import useScrollToTop from 'providers/useScrollToTop';

function App() {

  useScrollToTop()
  
  return (
    <MainProvider>
      <main>
        <HeaderComponent />
        <RoutesFile />
        <FooterComponent />
        </main>
    </MainProvider>
  );
}

export default App;
