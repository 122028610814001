import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

import Logo from 'render/assets/media/brand/logo-black-triggr-innovation.svg';
import FacebookIcon from 'render/assets/media/icons/facebook-icon.svg';
import LinkedinIcon from 'render/assets/media/icons/linkedin-icon.svg';
import YoutubeIcon from 'render/assets/media/icons/youtube-icon.svg';
import InstagramIcon from 'render/assets/media/icons/instagram-icon.svg';
import ChevronIcon from 'render/assets/media/icons/chevron-icon.svg';
import ButtonMenu from 'render/assets/media/auxiliary/button-menu-triggr-innovation.svg';
import ButtonClose from 'render/assets/media/auxiliary/button-close-triggr-innovation.svg';
import { ROUTES } from 'routes/RoutesFile';

import { useTranslation } from 'react-i18next';


function HeaderComponent() {

  const { t,i18n } = useTranslation();
  const [language, setLanguage] = useState('en');

  const [openDropdown, setOpenDropdown] = useState(null);
  const [menuState, setMenuState] = useState('closed');

  const toggleDropdown = (dropdownId) => {
    if (openDropdown === dropdownId) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(dropdownId);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.dropdown-group')) {
        setOpenDropdown(null);
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);


  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    setOpenDropdown(null);
    setMenuState('closed');
    i18n.changeLanguage(lang);
  };

  const handleResponsiveMenuButtonClick = () => {
    const newState = menuState === 'closed' ? 'open' : 'closed';
    setMenuState(newState);

    // Update the body element attribute
    document.body.setAttribute('responsive-menu-state', newState);
  }


  return (
    <>
      <header id="main-header">
        <div className="container">
          <div id="main-header--logo-container">
            <Link to="/"><img src={Logo} alt="" /></Link>
          </div>
          <button id="main-header--menu-btn" className="btn-s btn-square p-0" onClick={handleResponsiveMenuButtonClick}>
            <img src={ButtonMenu}/>
          </button>
          <nav id="main-header--main-nav">
            <div className="dropdown-group" onMouseEnter={() => toggleDropdown('nav1')} onMouseLeave={() => setOpenDropdown(null)}>
              <button type="button">{t('header.nav.company')}</button> {/* translated text */}
              {openDropdown === 'nav1' && (
                <div className="dropdown" align="left">
                  <Link to={ROUTES.ABOUT} onClick={() => setOpenDropdown(null)}>{t('header.nav.about')}</Link> {/* translated text */}
                  <Link to={ROUTES.TEAM} onClick={() => setOpenDropdown(null)}>{t('header.nav.team')}</Link> {/* translated text */}
                  <Link to={ROUTES.CAREERS} onClick={() => setOpenDropdown(null)}>{t('header.nav.careers')}</Link> {/* translated text */}
                </div>
              )}
            </div>
            <div className="dropdown-group" onMouseEnter={() => toggleDropdown('nav2')} onMouseLeave={() => setOpenDropdown(null)}>
              <button type="button">{t('header.nav.services')}</button> {/* translated text */}
              {openDropdown === 'nav2' && (
                <div className="dropdown" align="left">
                  <Link to={ROUTES.ECOSYSTEMDEVELOPMENT} onClick={() => setOpenDropdown(null)}>{t('header.nav.ecosystemDevelopment')}</Link> {/* translated text */}
                  <Link to={ROUTES.CORPORATEINNOVATION} onClick={() => setOpenDropdown(null)}>{t('header.nav.corporateInnovation')}</Link> {/* translated text */}
                </div>
              )}
            </div>
            <div className="dropdown-group" onMouseEnter={() => toggleDropdown('nav3')} onMouseLeave={() => setOpenDropdown(null)}>
              <button type="button">{t('header.nav.tools')}</button> {/* translated text */}
              {openDropdown === 'nav3' && (
                <div className="dropdown" align="left">
                  <Link to={ROUTES.EBOOKS} onClick={() => setOpenDropdown(null)}>{t('header.nav.ebooks')}</Link> {/* translated text */}
                  <Link to={ROUTES.TEMPLATES} onClick={() => setOpenDropdown(null)}>{t('header.nav.templates')}</Link> {/* translated text */}
                </div>
              )}
            </div>
            <Link to={ROUTES.CONTACTS}>{t('header.nav.contact')}</Link> {/* translated text */}
          </nav>
          <div id="main-header--secondary-nav-container">
            <nav id="main-header--secondary-nav">
              {/* <Link><img src={FacebookIcon} alt=""/></Link> */}
              <Link><img src={LinkedinIcon} alt=""/></Link>
              {/* <Link><img src={YoutubeIcon} alt=""/></Link> */}
              {/* <Link><img src={InstagramIcon} alt=""/></Link> */}
            </nav>
            <nav id="main-header--lang-nav">
              <div className="dropdown-group">
                <button onClick={() => toggleDropdown('lang')} type="button" className="btn-s btn-ghost uppercase">
                  <span>{language}</span>
                  <img className="icon" icon-size="xs" src={ChevronIcon} alt=""/>
                </button>

                {openDropdown === 'lang' && (
                  <div className="dropdown" align="right">
                    <button onClick={() => handleLanguageChange('en')}>
                      English
                    </button>
                    <button onClick={() => handleLanguageChange('pt')}>
                      Português
                    </button>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </div>
      </header>

      <div id="responsive-header">
        <div id="responsive-header--top-actions">
          <div className="dropdown-group">
            <button onClick={() => toggleDropdown('lang')} type="button" className="btn-s btn-ghost uppercase">
              <span>{language}</span>
              <img className="icon" icon-size="xs" src={ChevronIcon} alt=""/>
            </button>

            {openDropdown === 'lang' && (
              <div className="dropdown" align="left">
                <button onClick={() => handleLanguageChange('en')}>
                  English
                </button>
                <button onClick={() => handleLanguageChange('pt')}>
                  Português
                </button>
              </div>
            )}
          </div>
          <button className="btn-s btn-square p-0" onClick={handleResponsiveMenuButtonClick}>
            <img width="100%" src={ButtonClose}/>
          </button>
        </div>

        <nav id="responsive-header--main-nav">
          <div>
            <span>{t('header.nav.company')}</span>
            <div>
              <Link to={ROUTES.ABOUT} onClick={handleResponsiveMenuButtonClick}>{t('header.nav.about')}</Link> {/* translated text */}
              <Link to={ROUTES.TEAM} onClick={handleResponsiveMenuButtonClick}>{t('header.nav.team')}</Link> {/* translated text */}
              <Link to={ROUTES.CAREERS} onClick={handleResponsiveMenuButtonClick}>{t('header.nav.careers')}</Link> {/* translated text */}
            </div>
          </div>
          <div>
            <span>{t('header.nav.services')}</span>
            <div>
              <Link to={ROUTES.ECOSYSTEMDEVELOPMENT} onClick={handleResponsiveMenuButtonClick}>{t('header.nav.ecosystemDevelopment')}</Link> {/* translated text */}
              <Link to={ROUTES.CORPORATEINNOVATION} onClick={handleResponsiveMenuButtonClick}>{t('header.nav.corporateInnovation')}</Link> {/* translated text */}
            </div>
          </div>
          <div>
            <span>{t('header.nav.tools')}</span>
            <div>
              <Link to={ROUTES.EBOOKS} onClick={handleResponsiveMenuButtonClick}>{t('header.nav.ebooks')}</Link> {/* translated text */}
              <Link to={ROUTES.TEMPLATES} onClick={handleResponsiveMenuButtonClick}>{t('header.nav.templates')}</Link> {/* translated text */}
            </div>
          </div>
          <Link to={ROUTES.CONTACTS} onClick={handleResponsiveMenuButtonClick}>{t('header.nav.contact')}</Link> {/* translated text */}
        </nav>

        <nav id="responsive-header--secondary-nav">
          {/* <Link><img src={FacebookIcon} alt=""/></Link> */}
          <Link><img src={LinkedinIcon} alt=""/></Link>
          {/* <Link><img src={YoutubeIcon} alt=""/></Link> */}
          {/* <Link><img src={InstagramIcon} alt=""/></Link> */}
        </nav>
      </div>
    </>
  );

}

export default HeaderComponent
