export const generateFetchActionReducers = (actionType) => ({
    pending: (state) => {
        state.status = 'loading';
    },
    fulfilled: (state, action) => {
        state.status = 'succeeded';
        if (action.payload) {
            state[actionType] = action.payload;
        }
    },
    rejected: (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
    },
});

export const generateExtraReducers = (fetchActions) => (builder) => {
    fetchActions.forEach(({ action, reducers }) => {
        Object.entries(reducers).forEach(([key, reducer]) => {
            builder.addCase(action[key], reducer);
        });
    });
};

export const downloadFile = async (fileUrl, fileName) => {
    try {

        const response = await fetch(fileUrl);
        if (!response.ok) {
            throw new Error("File download error");
        }

        const contentType = response.headers.get('content-type');
        const blob = await response.blob();
        const blobWithType = new Blob([blob], { type: contentType }); 
        const blobUrl = window.URL.createObjectURL(blobWithType);
        const link = document.createElement('a');

        link.download = fileName;

        link.href = blobUrl;
        link.click();

        window.URL.revokeObjectURL(link.href);
    } catch (error) {
        console.error("File download error:", error);
    }
};

export const getLocalizedContent = (content, language, keys) => {
    // Determine the suffix based on the current language
    const suffix = language === 'pt' ? '_pt' : '_en'; // Defaulting to '_en' if not Portuguese

    // Create an object to hold the localized content
    let localizedContent = {};

    // Go through each key and get the localized content
    keys.forEach((key) => {
        const localizedKey = `${key}${suffix}`;
        localizedContent[key] = content[localizedKey];
    });

    return localizedContent;
};
