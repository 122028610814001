import { getLocalizedContent } from 'controller/scripts/Utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

function EbookEmptyComponent() {

    const { t,i18n } = useTranslation();

    return (
        <>
            <h3 className="col-all w-100 text-gray text-center">{t('ebooksPage.emptyMessage')}</h3>
        </>
    );
}

export default EbookEmptyComponent