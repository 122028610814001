import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from 'render/views/pages/HomePage';
import AboutPage from 'render/views/pages/AboutPage';
import TeamPage from 'render/views/pages/TeamPage';
import CareersPage from 'render/views/pages/CareersPage';
import EcosystemDevelopmentPage from 'render/views/pages/EcosystemDevelopmentPage';
import CorporateInnovationPage from 'render/views/pages/CorporateInnovationPage';
import EbooksPage from 'render/views/pages/EbooksPage';
import TemplatesPage from 'render/views/pages/TemplatesPage';
import ContactsPage from 'render/views/pages/ContactsPage';
import TermsPage from 'render/views/pages/TermsPage';
import PageNotFound from 'render/views/pages/PageNotFound';
import CareerDetailsPage from 'render/views/pages/CareerDetailsPage';

export const ROUTES = {
  HOME: '/',
  ABOUT: '/about-us',
  TEAM: '/our-team',
  CAREERS: '/careers',
  CAREER_DETAILS: '/careers/:slug/',
  ECOSYSTEMDEVELOPMENT: '/ecosystem-development',
  CORPORATEINNOVATION: '/corporate-innovation',
  EBOOKS: '/ebooks',
  TEMPLATES: '/templates',
  CONTACTS: '/contact-us',
  TERMS: '/terms',
};

const RoutesFile = () => {

  return (
    <Routes>
      <Route
        path={ROUTES.HOME}
        element={<HomePage />}
        exact
      />
      <Route
        path={ROUTES.ABOUT}
        element={<AboutPage />}
      />
      <Route
        path={ROUTES.TEAM}
        element={<TeamPage />}
      />
      <Route
        path={ROUTES.CAREERS}
        element={<CareersPage />}
      />
      <Route
        path={ROUTES.CAREER_DETAILS}
        element={<CareerDetailsPage />}
      />
      <Route
        path={ROUTES.ECOSYSTEMDEVELOPMENT}
        element={<EcosystemDevelopmentPage />}
      />
      <Route
        path={ROUTES.CORPORATEINNOVATION}
        element={<CorporateInnovationPage />}
      />
      <Route
        path={ROUTES.EBOOKS}
        element={<EbooksPage />}
      />
      <Route
        path={ROUTES.TEMPLATES}
        element={<TemplatesPage />}
      />
      <Route
        path={ROUTES.CONTACTS}
        element={<ContactsPage />}
      />
      <Route
        path={ROUTES.TERMS}
        element={<TermsPage />}
      />
      <Route
        path="*"
        element={<PageNotFound />} />

    </Routes>
  )
};

export default RoutesFile;
