import { downloadFile, getLocalizedContent } from 'controller/scripts/Utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

function TemplatesComponent({ templates }) {

    const {i18n} = useTranslation();

    return (
        <>
            {templates && templates.map((template, index) => {
                const localizedContent = template ? getLocalizedContent(template, i18n.language, ['title', 'description']) : null;
                return (
                    <div className="misc-item" key={index}>
                        <div className="image-container" ratio="1:1">
                            <img className="img-cover" src={`${process.env.REACT_APP_API_SERVER}/${template.photo}`} alt="" />
                        </div>
                        <h4 className="misc-item--name">{localizedContent.title}</h4>
                        <p>
                            {localizedContent.description}
                        </p>
                        <button
                            type="button"
                            className="btn btn-fill"
                            onClick={() => downloadFile(`${process.env.REACT_APP_API_SERVER}/${template.link}`, localizedContent.title)}
                        >
                            Download
                        </button>
                    </div>
                )
            })}
        </>
    );
}

export default TemplatesComponent