import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import ButtonArrow from 'render/assets/media/auxiliary/button-arrow-triggr-innovation.svg';
import TriggrSymbol from 'render/assets/media/brand/symbol-triggr-innovation.svg';
import { ROUTES } from 'routes/RoutesFile';
import { useTranslation, Trans } from 'react-i18next';
import { API_ENDPOINTS, postData } from 'controller/api/API';
import { toast } from 'react-toastify';

function FooterComponent() {

  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError('Invalid email format');
      toast.error(`Invalid email format:`);
      return;
    }

    setEmailError(''); 
    try {
      await postData(API_ENDPOINTS.POST_NEWSLETTER,{ email });
      toast.success(`Successfully subscribed!`);
      setEmail('');
  
    } catch (error) {
      setEmailError('Failed to update data');
      toast.error(`Something went wrong. Please try again.`);
    }
    
  };

  return (
    <>
      <footer id="contact-footer">
        <div id="contact-footer--symbol-pattern"></div>
        <Link to={ROUTES.CONTACTS} className="btn btn-fill btn-arrow"><span>{t('footer.contactUs')}</span><img src={ButtonArrow} alt="" /></Link>
      </footer>
      <footer id="main-footer">
        <div className="container">
          <div id="main-footer--logo-container">
            <Link><img src={TriggrSymbol} /></Link>
          </div>
          <div id="main-footer--phone-container">
            <a href="tel:+351914484649">(+351) 914 484 649</a>
          </div>
          <nav id="main-footer--main-nav">
            <h4>{t('footer.quickLinks')}</h4>
            <Link to={ROUTES.ECOSYSTEMDEVELOPMENT}>{t('footer.ecosystemDevelopment')}</Link>
            <Link to={ROUTES.CORPORATEINNOVATION}>{t('footer.corporateInnovation')}</Link>
            <Link to={ROUTES.CAREERS}>{t('footer.careers')}</Link>
            <Link to={ROUTES.CONTACTS}>{t('header.nav.contact')}</Link>
          </nav>
          <nav id="main-footer--secondary-nav">
            <h4>{t('footer.follow')}</h4>
            {/* <Link>Facebook</Link> */}
            <Link>Linkedin</Link>
            {/* <Link>Youtube</Link>
            <Link>Instagram</Link> */}
          </nav>
          <div id="main-footer--newsletter-container">
            <h4>{t('footer.subscribeInvite')}</h4>
            <form onSubmit={handleSubmit}>
              <div className="input-with-submit">
                <input
                  placeholder="E-MAIL"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit">
                  <img src={ButtonArrow} alt="Submit" />
                </button>
              </div>
              {emailError && <div className="mt-s"><span className="error">{emailError}</span></div>}
            </form>
            <p>
              <Trans i18nKey="footer.recaptchaStatement">
                {/* This site is protected by reCAPTCHA and the <Link className="inline-link">Google Privacy Policy</Link> and <Link to={ROUTES.TERMS} className="inline-link">Terms of Service</Link> apply. */}
                By subscribing, you are agreeing to the terms <Link to={ROUTES.TERMS} className="inline-link">Terms of Service</Link>.
              </Trans>
            </p>
          </div>
          <div id="main-footer--bottom">
            <div>
              <span>{t('footer.copyright')}</span>
            </div>
            <div>
              <Link>{t('footer.privacy')}</Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );

}

export default FooterComponent
