import { getLocalizedContent } from 'controller/scripts/Utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

function TestimonialComponent({ testimonials }) {

    const {i18n} = useTranslation();

    return (
        <>
            {testimonials && testimonials.map((testimonial, index) => {
                const localizedContent = testimonial ? getLocalizedContent(testimonial, i18n.language, ['text']) : null;
                return (
                    <div className="testimonial-item" key={index}>
                        <div className="testimonial-item--picture">
                            <img width="34px" src={`${process.env.REACT_APP_API_SERVER}/${testimonial.photo}`} alt="" />
                        </div>
                        <h4>{testimonial.name}</h4>
                        <p>{localizedContent.text}</p>
                    </div>
                )
            })}
        </>
    );
}

export default TestimonialComponent