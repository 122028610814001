import { getLocalizedContent } from 'controller/scripts/Utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ButtonArrow from 'render/assets/media/auxiliary/button-arrow-triggr-innovation.svg';
import { ROUTES } from 'routes/RoutesFile';


function CareerComponent({ careers }) {

    const { i18n } = useTranslation();

    return (
        <>
            {careers && careers.map((career, index) => {

                const keysToLocalize = ['title', 'description'];
                const localizedContent = getLocalizedContent(career, i18n.language, keysToLocalize);

                return (
                    <Link to={`${ROUTES.CAREERS}/${localizedContent.title}`} className="open-opportunity-item" key={index}>
                        <h4>{localizedContent.title}</h4>
                        <p>21/10/2023</p>
                        <img width="34px" src={ButtonArrow} alt=""/>
                    </Link>
                );
            })}
        </>
    );
}

export default CareerComponent