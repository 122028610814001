import { configureStore } from '@reduxjs/toolkit';
import TeamSlice from './TeamSlice';
import CareerSlice from './CareerSlice';
import EbooksSlice from './EbooksSlice';
import TemplatesSlice from './TemplatesSlice';
import TestimonialsSlice from './TestimonialsSlice';
import ClientsSlice from './ClientsSlice';

const store = configureStore({
  reducer: {
    team: TeamSlice,
    careers: CareerSlice,
    ebooks: EbooksSlice,
    templates: TemplatesSlice,
    testimonials: TestimonialsSlice,
    clients: ClientsSlice,
  },
});
export default store;