import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ButtonArrow from 'render/assets/media/auxiliary/button-arrow-triggr-innovation.svg';
import TestimonialComponent from '../components/TestimonialComponent';

import { useDispatch, useSelector } from 'react-redux';
import { fetchTestimonials } from 'controller/TestimonialsSlice';
import { API_ENDPOINTS, postData } from 'controller/api/API';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';

function ContactsPage() {

    const { t,i18n } = useTranslation();

    const { testimonials, isTestimonialsFetched } = useSelector((state) => state.testimonials);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isTestimonialsFetched) {
            dispatch(fetchTestimonials());
        }
    }, [dispatch, isTestimonialsFetched]);

    const [formData, setFormData] = useState({
        name: '',
        last_name: '',
        email: '',
        company: '',
        message: ''
    });
    const [error, setError] = useState('');

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(formData.email)) {
            setError('Invalid email format');
            toast.error(`Invalid email format:`);
            return;
        }

        setError(''); // Clear any existing errors

        try {
            await postData(API_ENDPOINTS.POST_CONTACTS, formData);

            // Clear the form fields after successful submission
            setFormData({
                name: '',
                last_name: '',
                email: '',
                company: '',
                message: ''
            });

            toast.success(`Message sent! We'll get back to you as soon as possible`);

            // Further actions after successful PUT request
        } catch (error) {
            setError('Failed to update data');
            toast.error(`Something went wrong. Please try again.`);
        }
    };

    return (
        <>
            <section id="contacts-form-section">
                <hr className="hr-header" />
                <div className="container grid-2 justify-between gap-l-on-breakpoint-xxl gap-0-on-breakpoint-l grid-6-on-breakpoint-m grid-1-on-breakpoint-xxs">
                    <div className="flex-column col-all-on-breakpoint-m flex-row-on-breakpoint-m justify-between-on-breakpoint-m flex-column-on-breakpoint-xxs gap-m-on-breakpoint-xxs">
                        <h1 className="pl-m pl-s-on-breakpoint-m pl-0-on-breakpoint-s">{t('contactsPage.get')}<br />{t('contactsPage.inTouch')}</h1>
                        <hr className="hr-xl hide-on-breakpoint-m" />
                        <div className="flex-column">
                            <h3 className="pl-xxl pl-xl-on-breakpoint-ml pl-0-on-breakpoint-m"><a href="tel:+351914484649">(+351) 914 484 649</a></h3>
                            <h3 className="pl-xxl pl-xl-on-breakpoint-ml pl-0-on-breakpoint-m">mail@mail.com</h3>

                            <div className="hide flex-on-breakpoint-m gap-m mt-auto mt-m-on-breakpoint-xxs">
                                {/* <Link>Facebook</Link> */}
                                <Link>Linkedin</Link>
                            </div>
                        </div>
                        <div className="mt-auto pl-xxl pl-xl-on-breakpoint-ml hide-on-breakpoint-m">
                            <div className="flex-row gap-m uppercase">
                                {/* <Link>Facebook</Link> */}
                                <Link>Linkedin</Link>
                                {/* <Link>Youtube</Link>
                                <Link>Instagram</Link> */}
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="contact-form col-start-3-on-breakpoint-m col-end-7-on-breakpoint-m mt-xl-on-breakpoint-m col-all-on-breakpoint-sm mt-l-on-breakpoint-xs">
                        <h3>{t('contactsPage.ReadyToTransform')}<br />{t('contactsPage.yourOrganization')}<br />{t('contactsPage.throughInnovation')}</h3>
                        <hr className="hr-l" />
                        <div className="grid-2 gap-l flex-on-breakpoint-ml flex-column-on-breakpoint-ml gap-m-on-breakpoint-ml gap-s-on-breakpoint-xxs">
                            <fieldset>
                                <input
                                    placeholder={t('contactForm.firstName')}
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </fieldset>
                            <fieldset>
                                <input
                                    placeholder={t('contactForm.lastName')}
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                />
                            </fieldset>
                            <fieldset>
                                <input
                                    placeholder={t('contactForm.email')}
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </fieldset>
                            <fieldset>
                                <input
                                    placeholder={t('contactForm.company')}
                                    name="company"
                                    value={formData.company}
                                    onChange={handleChange}
                                />
                            </fieldset>
                            <fieldset className="col-2">
                                <textarea
                                    placeholder={t('contactForm.yourMessage')}
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                ></textarea>
                            </fieldset>
                            <hr className="hr-m" />
                            <div className="col-2 justify-end">
                                <button type="submit" className="btn btn-fill btn-arrow"><span>{t('contactsPage.submit')}</span><img src={ButtonArrow} alt="" /></button>
                            </div>
                        </div>
                    </form>
                </div>
                <hr className="hr-xxxl hr-xxl-on-breakpoint-xl hr-xl-on-breakpoint-l hr-l-on-breakpoint-ml hr-m-on-breakpoint-sm" />
            </section>
            <section>
                <div className="container-m testimonials-container">
                    <header className="testimonials-header">
                        <h2>{t('contactsPage.WhatOurClientsSay')}</h2>
                        {/* <hr className="bg-black"/> */}
                    </header>
                    {testimonials && testimonials.length > 0 ?
                        <TestimonialComponent testimonials={testimonials}></TestimonialComponent> : null
                    }
                </div>
                <hr className="hr-xl" />
            </section>
        </>
    );

}

export default ContactsPage