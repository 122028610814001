import React from 'react';
import { Link } from 'react-router-dom';

import ButtonArrow from 'render/assets/media/auxiliary/button-arrow-triggr-innovation.svg';

import { ROUTES } from 'routes/RoutesFile';

import { useTranslation } from 'react-i18next';

function PageNotFound() {

  const { t,i18n } = useTranslation();

  return (
    <>
        <section>
            <hr className="hr-header" />
            <div className="container text-center">
                <h1>{t('404Page.title')}</h1>
                <hr className="hr-xl"/>
                <h2>
                  {t('404Page.subtitle')}
                </h2>
                <hr className="hr-m"/>
                <Link to={ROUTES.HOME} className="btn btn-fill btn-arrow"><span>{t('404Page.button')}</span><img src={ButtonArrow} alt="" /></Link>
            </div>
            <hr className="hr-header" />
        </section>
    </>
  );
  
}

export default PageNotFound
