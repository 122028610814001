import React from 'react';

import ButtonArrow from 'render/assets/media/auxiliary/button-arrow-triggr-innovation.svg';
import Image1 from 'render/assets/media/auxiliary/corporate-innovation-1-triggr-innovation.jpg';
import Image2 from 'render/assets/media/auxiliary/corporate-innovation-2-triggr-innovation.jpg';
import Image3 from 'render/assets/media/auxiliary/corporate-innovation-3-triggr-innovation.jpg';

import { useTranslation } from 'react-i18next';

function CorporateInnovationPage() {

    const { t,i18n } = useTranslation();

    return (
        <>
            <section className="bg-color-3">
                <hr className="hr-header"/>
                <div className="container">
                <h1>{t('corporateInnovationPage.titlePart1')}<br/><span className="pl-xl pl-ml-on-breakpoint-m pl-0-on-breakpoint-s">{t('corporateInnovationPage.titlePart2')}</span></h1>
                </div>
                <div className="container-m">
                    <hr className="hr-xxl hr-xl-on-breakpoint-ml hr-l-on-breakpoint-m"/>
                    <div className="grid-2 gap-xxl gap-xl-on-breakpoint-l gap-l-on-breakpoint-sm grid-8-on-breakpoint-s gap-0-on-breakpoint-s">
                        <div className="flex-column align-start gap-l col-start-2-on-breakpoint-s col-end-9-on-breakpoint-s">
                            <p className="text-l text-m-on-breakpoint-l text-sm-on-breakpoint-xxs">{t('corporateInnovationPage.text')}</p>
                            <div className="mt-auto">
                                <img src={ButtonArrow} className="arrow-img"/>
                            </div>
                        </div>
                        <img className="hide-on-breakpoint-s" width="100%" src={Image1}/>
                    </div>
                </div>
                <hr className="hr-header"/>
            </section>
            <section>
                <div className="container-m">
                    <div className="flex-row justify-between align-end gap-xl align-start-on-breakpoint-l flex-column-on-breakpoint-m">
                        <h2 className="quote">{t('corporateInnovationPage.item1Title')}</h2>
                        <div className="container-xs p-0 mx-0">
                            <p>{t('corporateInnovationPage.item1Description')}</p>
                        </div>
                    </div>
                    <hr className="hr-xxl hr-xl-on-breakpoint-m"/>
                    <h3>{t('corporateInnovationPage.item1ComponentsTitle')}</h3>
                    <hr className="hr-l"/>
                    <div className="key-features-container">
                        <div>
                            <div className="key-features-item">
                                <h4>{t('corporateInnovationPage.item1Component1Title')}</h4>
                                <p>{t('corporateInnovationPage.item1Component1Text')}</p>
                            </div>
                            <div className="key-features-item">
                                <h4>{t('corporateInnovationPage.item1Component2Title')}</h4>
                                <p>{t('corporateInnovationPage.item1Component2Text')}</p>
                            </div>
                            <div className="key-features-item">
                                <h4>{t('corporateInnovationPage.item1Component3Title')}</h4>
                                <p>{t('corporateInnovationPage.item1Component3Text')}</p>
                            </div>
                        </div>
                        <div className="image-container">
                            <img src={Image2}/>
                        </div>
                    </div>
                </div>
                <hr className="hr-xl"/>
            </section>
            <section className="bg-color-5">
                <div className="container-m">
                    <div className="flex-row justify-between align-end gap-xl align-start-on-breakpoint-l flex-column-on-breakpoint-m">
                        <h2 className="quote">{t('corporateInnovationPage.item2Title')}</h2>
                        <div className="container-xs p-0 mx-0">
                            <p>{t('corporateInnovationPage.item2Description')}</p>
                        </div>
                    </div>
                    <hr className="hr-xxl hr-xl-on-breakpoint-m"/>
                    <h3>{t('corporateInnovationPage.item2ComponentsTitle')}</h3>
                    <hr className="hr-l"/>
                    <div className="key-features-container">
                        <div>
                            <div className="key-features-item">
                                <h4>{t('corporateInnovationPage.item2Component1Title')}</h4>
                                <p>{t('corporateInnovationPage.item2Component1Text')}</p>
                            </div>
                            <div className="key-features-item">
                                <h4>{t('corporateInnovationPage.item2Component2Title')}</h4>
                                <p>{t('corporateInnovationPage.item2Component2Text')}</p>
                            </div>
                            <div className="key-features-item">
                                <h4>{t('corporateInnovationPage.item2Component3Title')}</h4>
                                <p>{t('corporateInnovationPage.item2Component3Text')}</p>
                            </div>
                        </div>
                        <div className="image-container">
                            <img src={Image2}/>
                        </div>
                    </div>
                </div>
                <hr className="hr-xl"/>
            </section>
        </>
    );
  
}
    
export default CorporateInnovationPage