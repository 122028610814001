import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import ButtonArrow from 'render/assets/media/auxiliary/button-arrow-triggr-innovation.svg';
import StampText from 'render/assets/media/auxiliary/stamp-text-triggr-innovation.svg';
import StampSymbol from 'render/assets/media/auxiliary/stamp-symbol-triggr-innovation.svg';
import Section2Img from 'render/assets/media/auxiliary/section-2-img-triggr-innovation.jpg';
import Section4Img from 'render/assets/media/auxiliary/section-4-img-triggr-innovation.jpg';
import Section5Img from 'render/assets/media/auxiliary/section-5-img-triggr-innovation.jpg';
import ClientsComponent from '../components/ClientsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClients } from 'controller/ClientsSlice';

import { useTranslation } from 'react-i18next';

function HomePage() {

  const { t,i18n } = useTranslation();

  const { clients, isClientsFetched } = useSelector((state) => state.clients);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isClientsFetched) {
      dispatch(fetchClients());
    }
  }, [dispatch, isClientsFetched]);

  
  return (
    <>
      <section id="home-section-1" className="bg-primary">
        <div className="container">
          <div id="stamp">
            <img src={StampSymbol}/>
            <img src={StampText}/>
          </div>
          <div>
            <h1>{t('home.section1.playground')}<br/>{t('home.section1.innovation')}</h1>
            <div>
              <Link className="btn btn-arrow-l p-0"><span>{t('home.section1.about')}</span><img src={ButtonArrow}/></Link>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-color-2 extra-padding" id="home-section-2">
        <div className="container grid-6 gap-xxxl gap-xxl-on-breakpoint-xxxxl grid-5-on-breakpoint-xxxl gap-xl-on-breakpoint-xxl grid-7-on-breakpoint-xl grid-8-on-breakpoint-l grid-1-on-breakpoint-sm gap-l-on-breakpoint-sm">
          <div className="col-4 col-3-on-breakpoint-xxxl col-4-on-breakpoint-xl col-5-on-breakpoint-l col-1-on-breakpoint-sm image-container" ratio="3:2">
            <img src={Section2Img} className="img-cover"/>
          </div>
          <div className="col-2 col-3-on-breakpoint-xl col-1-on-breakpoint-sm">
            <h3>
              {t('home.section2.weDontLetChangeHappenToUs')}
              <br/>
              {t('home.section2.weCreateIt')}
            </h3>
            <hr className="hr-xl hr-l-on-breakpoint-xxl hr-m-on-breakpoint-sm"/>
            <p>
            {t('home.section2.theWorldIsChanging')}
              <br/>{t('home.section2.andWeHaveAChoice')}
              <br/>
              {t('home.section2.weCanFollowTrends')}
              <br/>
              {t('home.section2.orWeCanCreateTheFuture')}
              <br/>
              {t('home.section2.thatsWhyWeAreHere')}
              <br/>
              {t('home.section2.togetherWeCreateTheFuture')}
              <br/>
              {t('home.section2.today')}
            </p>
          </div>
        </div>
        <hr className="hr-xxxl hr-xxl-on-breakpoint-xl hr-xl-on-breakpoint-sm"/>
        <div className="container-m">
          <h2>
            {t('home.section2.throughoutOurJourney')}
          </h2>
          {clients && clients.length > 0 ? (
            <>
              <hr className="hr-l" />
              <h5 className="uppercase">{t('home.section2.storiesWeAreProudOf')}</h5>
              <hr className="hr-l" />
              <div className="grid-5 grid-4-on-breakpoint-l grid-3-on-breakpoint-sm">
                <ClientsComponent clients={clients}></ClientsComponent>
              </div>
            </>
          ) : null}
        </div>
      </section>
      <section id="home-section-3">
        <div className="container-m">
          <h5 className="uppercase text-white">{t('home.section3.ourServices')}</h5>
          <hr className="hr-l"/>
          <div className="grid-2 gap-xl gap-l-on-breakpoint-sm grid-1-on-breakpoint-s gap-row-xs-on-breakpoint-s">
            <h2 className="bigger text-primary">{t('home.section3.ecosystem')}<br/>{t('home.section3.development')}</h2>
            <p className="text-white pr-xxxl pr-0-on-breakpoint-l mb-m-on-breakpoint-s">{t('home.section3.ecosystemDevelopmentDescription')}</p>
            <h2 className="bigger text-primary">{t('home.section3.corporate')}<br/>{t('home.section3.innovation')}</h2>
            <p className="text-white pr-xxxl pr-0-on-breakpoint-l">{t('home.section3.corporateInnovationDescription')}</p>
          </div>
        </div>
      </section>
      <section className="bg-color-3">
        <div className="container-m">
          <h5 className="uppercase text-white">{t('home.section4.whereAreWe')}</h5>
          <hr className="hr-l"/>
          <div className="grid-2 gap-xxl gap-xl-on-breakpoint-xxl gap-ml-on-breakpoint-s flex-on-breakpoint-xs flex-column-reverse-on-breakpoint-xs">
            <div className="flex-column align-start gap-l gap-s-on-breakpoint-xs">
              <h2>{t('home.section4.triggrInnovationIsAGlobalCompany')}</h2>
              <h2>{t('home.section4.basedInAveiroPortugal')}</h2>
              <div className="mt-auto mt-ml-on-breakpoint-s">
                <Link className="btn btn-arrow-l p-0"><span>{t('home.section4.about')}</span><img src={ButtonArrow}/></Link>
              </div>
            </div>
            <img className="image-with-max-width" width="100%" src={Section4Img}/>
          </div>
        </div>
      </section>
      <section>
        <div className="container-m">
          <div className="grid-2 gap-xxl gap-xl-on-breakpoint-xxl gap-ml-on-breakpoint-s flex-on-breakpoint-xs flex-column-on-breakpoint-xs">
            <img className="image-with-max-width" width="100%" src={Section5Img}/>
            <div className="flex-column align-start gap-l">
              <h2>{t('quote.weAreAnInnovationAgency')}</h2>
              <img className="arrow-img" src={ButtonArrow}/>
              <p className="mt-auto hide-on-breakpoint-sm">
                {t('quote.weGuideYou')}
                <br/><br/>
                {t('quote.weAreDealing')}
                <br/><br/>
                {t('quote.weDoItTogether')}
              </p>
            </div>
            <p className="hide show-on-breakpoint-sm col-all">
              {t('quote.weGuideYou')}
              <br/><br/>
              {t('quote.weAreDealing')}
              <br/><br/>
              {t('quote.weDoItTogether')}
            </p>
          </div>
          <hr className="hr-xxl hr-xl-on-breakpoint-ml hr-l-on-breakpoint-m hr-xl-on-breakpoint-sm"/>
          <hr className="hr-xxl hr-0-on-breakpoint-l"/>
          <div className="container-sm p-0 m-0">
            <h2 className="quote unlocking-quote">“{t('quote.unlocking')}<br/>{t('quote.tangibleImpact')}<br/>{t('quote.through')}<br/>{t('quote.collaborative')}<br/>{t('quote.innovation')}”</h2>
          </div>
          <hr className="hr-xxl hr-xl-on-breakpoint-l"/>
          <div className="grid-2 grid-3-on-breakpoint-sm grid-1-on-breakpoint-s">
            <p className="col-2-on-breakpoint-sm col-all-on-breakpoint-s">
              {t('quote.text')}
            </p>
          </div>
        </div>
      </section>
    </>
  );
  
}

export default HomePage
