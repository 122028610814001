import React from 'react';
import { useTranslation } from 'react-i18next';

function PageNotFound() {

  const { t } = useTranslation();

  return (
    <>
      <section>
        <hr className="hr-header" />
        <div className="container-sm">
          <h1>{t('termsPage.title')}</h1>
        </div>
        <hr className="hr-xl" />
        <div className="container-sm">
          <h3>{t('termsPage.text')}</h3>
          <hr className="hr-xl" />
          <div>
            <h3><b>{t('termsPage.siteProp')}</b></h3>
            <p>{t('termsPage.sitePropText')}</p>
          </div>
          <hr className="hr-l" />
          <div>
            <h3><b>{t('termsPage.siteUseTitle')}</b></h3>
            <p>{t('termsPage.siteUseText')}</p>
          </div>
          <hr className="hr-l" />
          <div>
            <h3><b>{t('termsPage.userGeneratedContentTitle')}</b></h3>
            <p>{t('termsPage.userGeneratedContentText')}</p>
          </div>
          <hr className="hr-l" />
          <div>
            <h3><b>{t('termsPage.intellectualPropertyTitle')}</b></h3>
            <p>{t('termsPage.intellectualPropertyText')}</p>
          </div>
          <hr className="hr-l" />
          <div>
            <h3><b>{t('termsPage.disclaimersTitle')}</b></h3>
            <p>{t('termsPage.disclaimersText')}</p>
          </div>
          <hr className="hr-l" />
          <div>
            <h3><b>{t('termsPage.terminationTitle')}</b></h3>
            <p>{t('termsPage.terminationText')}</p>
          </div>
          <hr className="hr-l" />
          <div>
            <h3><b>{t('termsPage.lawTitle')}</b></h3>
            <p>{t('termsPage.lawText')}</p>
          </div>
          <hr className="hr-l" />
          <div>
            <h3><b>{t('termsPage.changesToTermsTitle')}</b></h3>
            <p>{t('termsPage.changesToTermsText')}</p>
          </div>
          <hr className="hr-l" />
          <div>
            <p>{t('termsPage.finalNote')}</p>
          </div>
        </div>

        <hr className="hr-header" />

      </section>
    </>
  );

}

export default PageNotFound
