import { getLocalizedContent } from 'controller/scripts/Utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ValuesImage1 from 'render/assets/media/auxiliary/values-1-triggr-innovation.jpg';
import ValuesImage2 from 'render/assets/media/auxiliary/values-2-triggr-innovation.jpg';
import ValuesImage3 from 'render/assets/media/auxiliary/values-3-triggr-innovation.jpg';
import ValuesImage4 from 'render/assets/media/auxiliary/values-4-triggr-innovation.jpg';
import ValuesImage5 from 'render/assets/media/auxiliary/values-5-triggr-innovation.jpg';
import ValuesImage6 from 'render/assets/media/auxiliary/values-6-triggr-innovation.jpg';

function ValuesComponent() {

    const { t,i18n } = useTranslation();

    return (
        <>
            <section>
                <div className="container-ml">
                    <h5 className="uppercase">{t('values.title')}</h5>
                    <hr className="hr-l"/>
                    <div className="grid-20 grid-10-on-breakpoint-xxl grid-5-on-breakpoint-l gap-l-on-breakpoint-l flex-on-breakpoint-xs flex-column-on-breakpoint-xs gap-m-on-breakpoint-xs">
                        <div className="col-start-1 col-end-11 col-end-6-on-breakpoint-xxl col-3-on-breakpoint-l">
                            <figure className="image-container" ratio="3:2">
                            <figcaption className="bg-black absolute z-index-1 b-0 l-0 p-l pl-xl p-ml-on-breakpoint-l pl-l-on-breakpoint-l w-75">
                                <h3 className="text-color-6">{t('values.itemTitle1')}<br/><span className="text-white">{t('values.itemSubtitle1')}</span></h3>
                            </figcaption>
                            <img className="img-cover" src={ValuesImage1} alt=""/>
                            </figure>
                        </div>
                        <div className="col-10 col-5-on-breakpoint-xxl col-2-on-breakpoint-l">
                            <p className="container-xxxs p-0 m-0 text-color-4">
                                {t('values.itemDescription1')}
                            </p>
                        </div>
                    </div>

                    <hr className="hr-xxl hr-xl-on-breakpoint-xs"/>

                    <div className="grid-20 grid-10-on-breakpoint-xxl grid-5-on-breakpoint-l gap-l-on-breakpoint-l flex-on-breakpoint-xs flex-column-reverse-on-breakpoint-xs gap-m-on-breakpoint-xs">
                        <div className="col-8 col-4-on-breakpoint-xxl col-2-on-breakpoint-l justify-end">
                            <p className="container-xxxs p-0 m-0 text-color-4">
                            {t('values.itemDescription2')}
                            </p>
                        </div>
                        <div className="col-start-9 col-end-21 col-6-on-breakpoint-xxl col-3-on-breakpoint-l">
                            <figure className="image-container" ratio="3:2">
                            <figcaption className="bg-black absolute z-index-1 b-0 l-0 p-l pl-xl pl-xl p-ml-on-breakpoint-l pl-l-on-breakpoint-l w-75">
                                <h3 className="text-color-6">{t('values.itemTitle2')}</h3>
                            </figcaption>
                            <img className="img-cover" src={ValuesImage2} alt=""/>
                            </figure>
                        </div>
                    </div>

                    <hr className="hr-xxl hr-xl-on-breakpoint-xs"/>

                    <div className="grid-20 grid-10-on-breakpoint-xxl grid-5-on-breakpoint-l gap-l-on-breakpoint-l flex-on-breakpoint-xs flex-column-on-breakpoint-xs gap-m-on-breakpoint-xs">
                        <figure className="image-container col-start-3 col-end-11 col-6-on-breakpoint-xxl col-3-on-breakpoint-l" ratio="3:2">
                            <figcaption className="bg-black absolute z-index-1 b-0 l-0 p-l pl-xl pl-xl p-ml-on-breakpoint-l pl-l-on-breakpoint-l w-75">
                            <h3 className="text-color-6">{t('values.itemTitle3')}</h3>
                            </figcaption>
                            <img className="img-cover" src={ValuesImage3} alt=""/>
                        </figure>
                        <div className="col-9 col-4-on-breakpoint-xxl col-2-on-breakpoint-l">
                            <p className="container-xxxs p-0 m-0 text-color-4">
                                {t('values.itemDescription3')}
                            </p>
                        </div>
                    </div>

                    <hr className="hr-xxl hr-xl-on-breakpoint-xs"/>

                    <div className="grid-20 grid-10-on-breakpoint-xxl grid-5-on-breakpoint-l gap-l-on-breakpoint-l flex-on-breakpoint-xs flex-column-reverse-on-breakpoint-xs gap-m-on-breakpoint-xs">
                        <div className="col-8 col-4-on-breakpoint-xxl col-2-on-breakpoint-l justify-end">
                            <p className="container-xxxs p-0 m-0 text-color-4">
                                {t('values.itemDescription4')}
                            </p>
                        </div>
                        <figure className="image-container col-start-9 col-end-18 col-6-on-breakpoint-xxl col-3-on-breakpoint-l" ratio="3:2">
                            <figcaption className="bg-black absolute z-index-1 b-0 l-0 p-l pl-xl pl-xl p-ml-on-breakpoint-l pl-l-on-breakpoint-l w-75">
                            <h3 className="text-color-6">{t('values.itemTitle4')}</h3>
                            </figcaption>
                            <img className="img-cover" src={ValuesImage4} alt="" />
                        </figure>
                    </div>

                    <hr className="hr-xxl hr-xl-on-breakpoint-xs"/>

                    <div className="grid-20 grid-10-on-breakpoint-xxl grid-5-on-breakpoint-l gap-l-on-breakpoint-l flex-on-breakpoint-xs flex-column-on-breakpoint-xs gap-m-on-breakpoint-xs">
                        <figure className="image-container col-start-1 col-end-10 col-6-on-breakpoint-xxl col-3-on-breakpoint-l" ratio="3:2">
                            <figcaption className="bg-black absolute z-index-1 b-0 l-0 p-l pl-xl pl-xl p-ml-on-breakpoint-l pl-l-on-breakpoint-l w-75">
                            <h3 className="text-color-6">{t('values.itemTitle5')}</h3>
                            </figcaption>
                            <img className="img-cover" src={ValuesImage5} alt=""/>
                        </figure>
                        <div className="col-10 col-4-on-breakpoint-xxl col-2-on-breakpoint-l">
                            <p className="container-xxxs p-0 m-0 text-color-4">
                                {t('values.itemDescription5')}
                            </p>
                        </div>
                    </div>

                    <hr className="hr-xxl hr-xl-on-breakpoint-xs"/>

                    <div className="grid-20 grid-10-on-breakpoint-xxl grid-5-on-breakpoint-l gap-l-on-breakpoint-l flex-on-breakpoint-xs flex-column-reverse-on-breakpoint-xs gap-m-on-breakpoint-xs">
                        <div className="col-7 col-4-on-breakpoint-xxl col-2-on-breakpoint-l justify-end">
                            <p className="container-xxxs p-0 m-0 text-color-4">
                                {t('values.itemDescription6')}
                            </p>
                        </div>
                        <figure className="image-container col-start-8 col-end-18 col-6-on-breakpoint-xxl col-3-on-breakpoint-l" ratio="3:2">
                            <figcaption className="bg-black absolute z-index-1 b-0 l-0 p-l pl-xl pl-xl p-ml-on-breakpoint-l pl-l-on-breakpoint-l w-75">
                            <h3 className="text-color-6">{t('values.itemTitle6')}</h3>
                            </figcaption>
                            <img className="img-cover" src={ValuesImage6} alt=""/>
                        </figure>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ValuesComponent