import { fetchCareers } from "controller/CareerSlice";
import { getLocalizedContent } from "controller/scripts/Utils";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';

function CareerDetailsPage() {
    const { slug } = useParams();
    const { careers, isCareersFetched } = useSelector((state) => state.careers);
    const dispatch = useDispatch();

    const { i18n } = useTranslation();

    useEffect(() => {
        if (!isCareersFetched) {
            dispatch(fetchCareers());
        }
    }, [dispatch, isCareersFetched]);

    const careerTitle = decodeURIComponent(slug);

    const career = careers.find(career => career.title_en === careerTitle || career.title_pt === careerTitle);
    const localizedContent = career ? getLocalizedContent(career,  i18n.language, ['title', 'description']) : null;

    return (
        localizedContent ?
            <>
                <section>
                    <hr className="hr-header" />
                    <div className="container-ml">
                        <h1><span className="pl-m pl-s-on-breakpoint-m pl-0-on-breakpoint-s">Open</span><br />Opportunity</h1>
                        <hr className="hr-xl hr-sm-on-breakpoint-m" />
                        <div className="grid-2 grid-5-on-breakpoint-xl gap-l-on-breakpoint-m grid-8-on-breakpoint-s gap-0-on-breakpoint-s grid-1-on-breakpoint-xxs">
                            <h3 className="pl-l pl-0-on-breakpoint-s col-all-on-breakpoint-m">{localizedContent.title}</h3>
                            <hr className="hide col-all hr-0 show-on-breakpoint-s hr-l-on-breakpoint-s"/>
                            <div className="container-s p-0 mx-0 col-start-3-on-breakpoint-xl col-end-6-on-breakpoint-xl col-start-2-on-breakpoint-m col-end-9-on-breakpoint-s col-all-on-breakpoint-xxs">
                                <p className="pl-l pl-0-on-breakpoint-sm">{localizedContent.description}</p>
                            </div>
                        </div>
                    </div>
                    <hr className="hr-xxxl" />
                </section>
            </>
            :
            <>
                <section>
                    <hr className="hr-header" />
                    <div className="container text-center">
                        <h1>Not Found</h1>
                        <hr className="hr-xl"/>
                        <h2>
                            No open opportunities were found with this address.
                            <br/>
                            Maybe it's no longer available.
                        </h2>
                    </div>
                    <hr className="hr-header" />
                </section>
            </>
    );
}

export default CareerDetailsPage;
