import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateFetchActionReducers, generateExtraReducers } from 'controller/scripts/Utils';
import * as api from 'controller/api/API';

const initialState = {
    careers: [],
    status: 'idle',
    error: null,
    isCareersFetched: false
};

const TEAM_ACTIONS = {
    FETCH_CAREERS: 'careers/fetchCareers',
};

export const fetchCareers = createAsyncThunk(
    TEAM_ACTIONS.FETCH_CAREERS,
    async () => {
        const response = await api.getReturnData(api.API_ENDPOINTS.GET_CAREERS);
        return response;
    }
);

const fetchActions = [
    { action: fetchCareers, reducers: generateFetchActionReducers('careers') },    
];

const CareersSlice = createSlice({
    name: 'careers',
    initialState,
    reducers: {
        setCareersFetched: (state, action) => {
            state.isCareersFetched = action.payload;
        },
    },
    extraReducers: generateExtraReducers(fetchActions),
});

export const { setCareersFetched } = CareersSlice.actions;

export default CareersSlice.reducer;