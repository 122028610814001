
import React from 'react';

function ClientsComponent({ clients }) {

    return (
        <>
            {Array.isArray(clients) && clients.map((client, index) => {
                return (
                    <div className="cliente-logo-item" key={index}><img src={`${process.env.REACT_APP_API_SERVER}/${client.photo}`} alt={client.name}/></div>
                )

            })}
        </>
    );
}

export default ClientsComponent