import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateFetchActionReducers, generateExtraReducers } from 'controller/scripts/Utils';
import * as api from 'controller/api/API';

const initialState = {
    templates: [],
    status: 'idle',
    error: null,
    isTemplatesFetched: false
};

const TEAM_ACTIONS = {
    FETCH_TEMPLATES: 'templates/fetchTemplates',
};

export const fetchTemplates = createAsyncThunk(
    TEAM_ACTIONS.FETCH_TEMPLATES,
    async () => {
        const response = await api.getReturnData(api.API_ENDPOINTS.GET_TEMPLATES);
        return response;
    }
);

const fetchActions = [
    { action: fetchTemplates, reducers: generateFetchActionReducers('templates') },    
];

const TemplatesSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        setTemplatesFetched: (state, action) => {
            state.isTemplatesFetched = action.payload;
        },
    },
    extraReducers: generateExtraReducers(fetchActions),
});

export const { setTemplatesFetched } = TemplatesSlice.actions;

export default TemplatesSlice.reducer;