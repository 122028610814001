import { fetchTeam } from 'controller/TeamSlice';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TeamComponent from '../components/TeamComponent';

import { useTranslation } from 'react-i18next';

function TeamPage() {

    const { t,i18n } = useTranslation();
    const { team, isTeamFetched } = useSelector((state) => state.team);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isTeamFetched) {
            dispatch(fetchTeam());
        }
    }, [dispatch, isTeamFetched]);

    return (
        <>
            <section className="bg-color-5">
                <hr className="hr-header" />
                <div className="container">
                    <h1><span className="pl-m pl-s-on-breakpoint-m pl-0-on-breakpoint-s">{t('teamPage.titlePart1')}</span><br />{t('teamPage.titlePart2')}</h1>
                    <hr className="hr-m hr-s-on-breakpoint-m hr-xs-on-breakpoint-s"/>
                    <h3 className="pl-xxl pl-xl-on-breakpoint-l pl-0-on-breakpoint-s">{t('teamPage.subtitle')}</h3>
                    <hr className="hr-0 hr-l-on-breakpoint-m"/>
                    <div className="grid-2 justify-end grid-5-on-breakpoint-xl grid-8-on-breakpoint-s gap-0-on-breakpoint-s grid-1-on-breakpoint-xxs">
                        <div className="col-start-2 col-start-3-on-breakpoint-xl col-end-6-on-breakpoint-xl col-start-2-on-breakpoint-m col-end-9-on-breakpoint-s">
                            <p className="text-l text-ml-on-breakpoint-l text-m-on-breakpoint-s text-sm-on-breakpoint-xxs">
                                {t('teamPage.textPart1')}<b>{t('teamPage.textPart2')}</b>{t('teamPage.textPart3')}
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="hr-xxxl" />
            </section>
            <section>
                <div className="container">
                    <div className="grid-3 gap-xxxl gap-xxl-on-breakpoint-xxl gap-xl-on-breakpoint-l grid-1-on-breakpoint-m">
                        <TeamComponent team={team}></TeamComponent>
                    </div>
                </div>
            </section>
        </>
    );

}

export default TeamPage