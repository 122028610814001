import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateFetchActionReducers, generateExtraReducers } from 'controller/scripts/Utils';
import * as api from 'controller/api/API';

const initialState = {
    team: [],
    status: 'idle',
    error: null,
    isTeamFetched: false
};

const TEAM_ACTIONS = {
    FETCH_TEAM: 'team/fetchTeam',
};

export const fetchTeam = createAsyncThunk(
    TEAM_ACTIONS.FETCH_TEAM,
    async () => {
        const response = await api.getReturnData(api.API_ENDPOINTS.GET_TEAM);
        return response;
    }
);

const fetchActions = [
    { action: fetchTeam, reducers: generateFetchActionReducers('team') },    
];

const TeamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setTeamFetched: (state, action) => {
            state.isTeamFetched = action.payload;
        },
    },
    extraReducers: generateExtraReducers(fetchActions),
});

export const { setTeamFetched } = TeamSlice.actions;

export default TeamSlice.reducer;